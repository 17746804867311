import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const BahaullahUndSeinAnspruch = () => (
    <InnerPage title="Zitate – Bahá’u’lláh und Sein Anspruch" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Bahá’u’lláh und Sein Anspruch"
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Bahá’u’lláh und Sein Anspruch',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Tabernakel der Einheit">
                    Der allwissende Arzt legt Seinen Finger an den Puls der
                    Menschheit. Er erkennt die Krankheit und verschreibt in
                    Seiner unfehlbaren Weisheit die Arznei. Jede Zeit hat ihre
                    besonderen Nöte, jede Seele ihre eigenen Sehnsucht. Für die
                    Krankheit der Welt von heute gibt es ein Heilmittel, für die
                    Welt von morgen ein anderes. Sorgt euch um die Nöte eurer
                    Zeit, und konzentriert euch auf ihre Bedürfnisse.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Anspruch und Verkündigung">
                    Die wirksamste Arznei, das mächtigste Mittel, das der Herr
                    für die Heilung der Welt verfügt hat, ist die Vereinigung
                    aller Völker in einer allumfassenden Sache, in einem
                    gemeinsamen Glauben. Nur ein allmächtiger, erleuchteter Arzt
                    hat die Fähigkeit, diese Einheit zu stiften.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Botschaften aus Akka">
                    O Volk! verkehre mit den Anhängern aller Religionen im Geist
                    des Wohlwollens und der Verbundenheit.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Beantwortete Fragen">
                    Die Notwendigkeit eines Erziehers für den Menschen ist
                    demnach klar und deutlich erwiesen. Dieser Erzieher muß
                    zweifellos auf allen Gebieten vollkommen sein und alle
                    anderen Menschen übertreffen, denn sonst könnte er nicht ihr
                    Erzieher sein, um so mehr, da er ihr körperlicher,
                    menschlicher und zugleich geistiger Erzieher sein muß. Dies
                    bedeutet, daß er die Menschen lehrt, die materiellen Dinge
                    zu ordnen und voranzubringen und die menschliche
                    Gemeinschaft so zu gestalten, daß gegenseitige Hilfe und
                    Fürsorge eingerichtet und alle materiellen Fragen in
                    jeglichen Verhältnissen geordnet und geregelt werden.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Beantwortete Fragen">
                    Ebenso wird er die Erziehung des Menschen in die Wege
                    leiten. Das heißt, er muß Verstand und Gedanken so erziehen,
                    daß sie umfassende Fortschritte machen können, so daß
                    Wissenschaften und Erkenntnisse sich auf größere Gebiete
                    ausdehnen und die Wirklichkeit der Dinge, die Geheimnisse
                    der Geschöpfe und die Eigentümlichkeiten des Daseins
                    entdeckt, Unterweisungen, Erfindungen und Gesetze mit jedem
                    Tag verbessert werden, und daß Schlüsse aus den
                    Sinneswahrnehmungen der materiellen Welt in bezug auf die
                    geistige Welt gezogen werden können.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default BahaullahUndSeinAnspruch;
